$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
    }
});

$(document).ready(function () {
    $('#project_id, #serial_number, #hwid').change(function () {
        var project_id = $('#project_id').val();
        var serial_number = $('#serial_number').val();
        var hwid = $('#hwid').val();
        if (project_id && serial_number && hwid) {
            $.ajax({
                url: "/devices/check",
                type: "post",
                data: {project_id: project_id, serial_number: serial_number, HWID: hwid},
                success: function (data) {
                    console.log(data)
                    if (data['status'] == 'error') {
                        $('#project_id, #serial_number, #hwid').removeClass('input-success');
                        $('#project_id, #serial_number, #hwid').addClass('input-error');

                        $('#hwid').next().remove();
                        $('#hwid').after('<div class="form-input-mess center error"> <p class="error">' + data['msg'] + '</p> </div>');

                        //Disable submit button
                        $('#submit').attr("disabled", true).addClass('disabled');
                    } else {
                        $('#project_id, #serial_number, #hwid').removeClass('input-error');
                        $('#project_id, #serial_number, #hwid').addClass('input-success');
                        $('#hwid').next().remove();
                        $('#hwid').after('<div class="form-input-mess center success"> <p class="success">' + data['msg'] + '</p> </div>');

                        //Enable submit button
                        $('#submit').removeAttr("disabled").removeClass('disabled');
                    }

                }
            })
        }
    });

    $("#hwid").inputmask({
        mask: "****-****-****-****",
        placeholder: " "
    });

    $('.single-click').click(function (e) {
        $(this).attr('disabled', true);
        $(this).closest("form").submit();
    });

    $('#menu-toggle').on('click', function(event){
        event.stopPropagation() ;
        toggleSidebar()
    });

    $('.top-nav-right ul .lang').hover(function(){
        $('.top-nav-right .submenu').toggleClass('visibility');
    });
});

$(document).on('click touchstart',function(e){
    if(e.target.nodeName != ('A') && $('#sidebar').hasClass('active-toggle')){
        toggleSidebar();
    }
});

//Update header cart products count.
function getCartCount() {
    $.ajax({
        url: "/cart/count",
        type: "post",
        success: function (data) {
            $('#header-cart').html(data)
        }
    })
}

function closeOrder(orderId, element, confirmMessage) {
    if (confirm(confirmMessage)) {
        $.ajax({
            url: "/order/close",
            type: "post",
            data: {
                id: orderId
            },
            success: function (data) {
                element.parent().parent().remove();
            }
        });
    }
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function toggleSidebar(){
    //$('body').toggleClass('sidebar-open');
    $('.navbar').toggleClass('active-toggle');
    $('.main-container').toggleClass('active-toggle');
    $('.sidebar-overlay').toggleClass('open');
    $('#sidebar').toggleClass('active-toggle');
    $('.content-container').toggleClass('no-scroll');
}

function removeDevice(deviceId) {
    if (confirm(removeLinkText)) {
        $.ajax({
            url: '/device/unlink',
            type: 'delete',
            data: {
                'device_id': deviceId
            },
            success: function (data) {
                window.location.href = '/devices';
            }
        })
    }
}

//Change tabs on device info page
function changeTabs(tab) {
    $('.tabs-menu li a').removeClass("current");
    $(tab).addClass("current");
    var tab_link = $(tab).attr("href");
    $(".tabs-content").not(tab_link).css("display", "none");
    $(tab_link).fadeIn();
}
